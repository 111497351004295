<script src="../../assets/language/en.js"></script>
<template>
    <div>
        <div class="SecondTitle">
            <span>
                {{ $t("enterOTP.secondTitle") }}
            </span>
        </div>
        <div>
            <span class="tips">
                {{ $t("enterOTP.tips") }}
            </span>
        </div>

        <div style="margin-top: 20px;">
            <input
                            id="otpShadow"
                            ref="cardNo"
                            type="tel"
                            v-model="otpValue"
                            center
                    />

                <van-password-input  :value="otpValue" :gutter="10" :mask="false" :length=otpValueLength
                 @click="showSystemKeyboard()" :focused="showKeyboard" @focus="showKeyboard = true"/>
            <!-- <van-password-input  :value="otpValue" :gutter="10" :mask="false" :length=otpValueLength
                :focused="showKeyboard" @focus="showKeyboard = true" /> -->
            <!-- 数字键盘 -->
            <!-- <van-number-keyboard v-model="otpValue" :show="showKeyboard" @blur="showKeyboard = false" /> -->
        </div>

        <div>
            <p class="resend-otp" v-if="number != 0">
                Resend OTP: {{ this.number }}s
            </p>
            <p class="resend-otp" v-else @click="start(1)">
                {{ $t("enterOTP.resendOTP") }}
            </p>
        </div>

        <van-button  @click="VerifyOtp" :disabled="((otpValue.length != otpValueLength)||pageLoading)" size="large" round
            class="button-verify">
            <!-- <van-loading class="loading-bg" type="spinner" color="#ff5d5d"  v-show="pageLoading"/> -->
            {{ $t("enterOTP.verify") }}
        </van-button>

        <van-dialog
                v-model="showDialog"
                :show-confirm-button="false">
            <template v-slot:title>
                <p style="text-align: left">
                    {{ $t("enterOTP.invalidOTP") }}
                </p>
            </template>
            <template v-slot:default>
                <div>
                    <p>
                        {{ $t("enterOTP.OTPRuleMessage") }}
                    </p>
                    <van-button style="width: 95%;margin-bottom: 10px" class="button-verify" @click="closeDialog()">
                        {{ $t("enterOTP.ok") }}
                    </van-button>
                </div>
            </template>
        </van-dialog>

        <van-dialog v-model="showDialog2" :show-confirm-button="false">
            <template v-slot:title>
                <p style="text-align: left">
                    Pass
                </p>
            </template>
            <template v-slot:default>
                <div>
                    <p>
                        Successfully
                    </p>
                    <van-button style="width: 95%;margin-bottom: 10px" class="button-verify" @click="closeDialog()">
                        {{ $t("enterOTP.ok") }}
                    </van-button>
                </div>
            </template>
        </van-dialog>
        <!-- <van-loading class="loading-bg" type="spinner" color="#ff5d5d"  v-show="pageLoading"/> -->
    </div>
</template>

<script>
    import {
        Dialog,
        PasswordInput,
        NumberKeyboard,
        Switch,
        Cell,
        DropdownMenu,
        DropdownItem,
        Divider,
        Button,
        Field,
        Toast,
        Loading
    } from 'vant';
    import { generateOtp, VerifyOtp, relateSearch } from '@/api/eformApi';
    export default {
        name: "EnterNumber",
        components: {
            [Dialog.Component.name]: Dialog.Component,
            [NumberKeyboard.name]: NumberKeyboard,
            [PasswordInput.name]: PasswordInput,
            [Switch.name]: Switch,
            [Cell.name]: Cell,
            [DropdownItem.name]: DropdownItem,
            [DropdownMenu.name]: DropdownMenu,
            [Divider.name]: Divider,
            [Button.name]: Button,
            [Field.name]: Field
        },
        watch: {
            otpValue() {
                // this.otpValue = this.otpShadow
                if (this.otpValue.length > this.otpValueLength) {
                    this.otpValue = this.otpValue.substring(0, this.otpValueLength)
                }
            }
        },
        created() {
            this.phoneNumber = this.$route.params.phoneNumber
            this.otpValueLength = localStorage.getItem('countryPath')=='sg'?5:6
            this.linkToken = sessionStorage.getItem('link_token')
            // this.sendOtp()
            this.start(0)
            this.checkBrandName()
        },
        data() {
            return {
                showKeyboard: false,
                otpValue: '',
                number: 60,
                time: '',
                isValidOtp: true,
                showDialog: false,
                showDialog2: false,
                secondTitle: false,
                phoneNumber: '',
                otpValueLength:0,
                pageLoading:false,
                linkToken:'',
                otpShadow:'',
                brandName:'',
                country:'',
            };
        },
        methods: {
            sendOtp() {
                generateOtp({
                    phoneno:this.phoneNumber,
                    country: localStorage.getItem('countryPath'),
                    brandName: this.brandName
                })
            },
            closeDialog() {
                this.showDialog=false
                this.showDialog2 = false
            },
            showSystemKeyboard() {
                this.showKeyboard = true
                this.$refs.cardNo.focus()
                
                
            },
            checkBrandName(){
                let brandList = ['esteelauder','bobbibrown','lamer','labseries','origins']
                this.brandName = brandList.includes(location.hostname.split('.')[1])?location.hostname.split('.')[1]:'origins'
                this.country =  localStorage.getItem('countryPath')
            },
            VerifyOtp() {
                // Toast.loading({
                //     duration: 0, // 持续展示 toast
                //     forbidClick: true, // 禁用背景点击
                //     loadingType: 'spinner',
                //     message: 'Verifying...'
                // });
                //TODO: gray button
                this.pageLoading = true;
                console.log(this.country=='th'?sessionStorage.getItem('th_request_id'):'')
                VerifyOtp({
                    phoneno: this.phoneNumber,
                    pincode: this.otpValue,
                    country: localStorage.getItem('countryPath'),
                    brandName: this.brandName,
                    request_id: this.country=='th'?sessionStorage.getItem('th_request_id'):'',
                }).then((res) => {
                    if (res.success) {
                        relateSearch({
                            phoneno: this.phoneNumber,
                            country: localStorage.getItem('countryPath'),
                            linkToken: this.linkToken,
                            brandName: this.brandName
                        }).then((res)=>{
                            if(res.success){
                                if(res.data!=1){
                                    if(res.data==0){
                                        this.secondTitle = true
                                    }else{
                                        this.secondTitle = false
                                    }
                                    console.log(this.phoneNumber)
                                    this.$router.push({
                                        name: 'Registration',
                                        params: {
                                            phoneNumber: this.phoneNumber,
                                            secondTitle: this.secondTitle,
                                            countryPath: localStorage.getItem('countryPath')
                                        }
                                    })
                                }else{
                                    this.$router.push({
                                        name: 'LinkConsumer',
                                        params: {
                                            countryPath: localStorage.getItem('countryPath')
                                        }
                                    })
                                }
                                this.pageLoading = false;
                                
                            }else{
                                Toast.fail(res.data)
                                this.pageLoading = false;
                                // this.otpValue = ''
                                // this.$router.push(`EnterNumber/${localStorage.getItem('country')}`)
                                // this.$router.push({
                                //         name: 'LinkConsumer',
                                //         params: {
                                //             countryPath: localStorage.getItem('countryPath')
                                //         }
                                //     })
                                
                            }
                        })  
                    } else {
                        this.showDialog = true
                        this.otpValue = ''
                        this.pageLoading = false;
                    }
                })
            },
            start(type) {
                if (type == 0){
                    let that = this
                    this.time=setInterval(()=>{
                        that.number--
                        if (that.number == 0){
                            clearInterval(that.time)
                        }
                    },1000)
                } else {
                    if (this.number == 0) {
                        generateOtp({
                            phoneno: this.phoneNumber,
                            country: localStorage.getItem('countryPath'),
                            brandName: localStorage.getItem('brandName')
                        }).then((res)=>{
                         if(res.success){
                            Toast.success(this.$t('enterNumber.toastSuccess'))
                            this.number = 60
                            let that = this
                            this.time=setInterval(()=>{
                                that.number--
                                if (that.number == 0){
                                    clearInterval(that.time)
                                }
                            },1000)
                         }else{
                            Toast.fail(res.data)
                            // this.number =5
                            // let that = this
                            // this.time=setInterval(()=>{
                            //     that.number--
                            //     if (that.number == 0){
                            //         clearInterval(that.time)
                            //     }
                            // },1000)
                         }
                        })
                    }
                }
            }
        }
    }
</script>

<style scoped>
>>>.van-popup--top {
    width: 25%;
    padding-left: 16px
}

.button-verify {
    display: inline;
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 20px;
}

.resend-otp {
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    font-family: Helvetica-Regular;
}

.van-password-input__security li {
    background-color: #ececec;
    border-radius: 6px;
}

>>>.van-dialog__header {
    padding-top: 0px;
    margin-left: 5%;
}

>>>.van-dialog__content {
    margin-left: 5%;
}

>>>.van-dialog {
    border-radius: 6px;
}
#otpShadow{
    border-style: none;
    width: 100;
    height: 0;
    padding:0;
}
</style>
